import React, { Component } from 'react';
import { TimelineMax } from 'gsap';
import './styles.css';
/* eslint-disable */

class CarouselCopy extends Component {
  constructor(props) {
    super(props);
    this.copy1 = null;
    this.copy2 = null;
    this.copy3 = null;
    this.myTween = new TimelineMax({ repeat: -1 });
  }

  componentDidMount() {
    this.myTween
      .to([this.copy1, this.copy2, this.copy3], 0, {
        alpha: 0
      })
      .to(this.copy1, 0.8, {
        alpha: 1
      })
      .to(this.copy1, 1, {
        delay: 1.6,
        alpha: 0
      })
      .to(this.copy2, 2.1, {
        alpha: 1
      })
      .to(this.copy2, 1, {
        delay: 0.4,
        alpha: 0
      })
      .to(this.copy3, 1, {
        alpha: 1
      })
      .to(this.copy3, 1, {
        delay: 3.1,
        alpha: 0
      });
  }

  render() {
    return (
      <div className="textContainer">
        <div className="copy" ref={div => (this.copy1 = div)}>
          There&apos;s holiday magic
          <br />
          all around you.
        </div>
        <div className="copy" ref={div => (this.copy2 = div)}>
          Look for "Laugh," "Magic,"
          <br />
          "Spirit" and "Wonder."
        </div>
        <div className="copy" ref={div => (this.copy3 = div)}>
          Scan to reveal an experience
          <br />
          and unwrap joy!
        </div>
      </div>
    );
  }
}

export default CarouselCopy;
