import ThreejsPipeline from './ThreejsPipeline'

export default new class ARPipeline {
  constructor () {
    this.name = 'att-holiday-ar'
    this.listeners = [
      // { event: 'reality.imageloading', process: this.logEvent },
      // { event: 'reality.imagescanning', process: this.logEvent },
      { event: 'reality.imagefound', process: this.onFoundImageTarget },
      { event: 'reality.imageupdated', process: this.onUpdatedImageTarget },
      { event: 'reality.imagelost', process: this.onLostImageTarget }
    ]
  }

  setExperience = experience => {
    this.experience = experience
  }
  
  onLoadEvent = cb => {
    ;(this.loadEvents = this.loadEvents || []).push(cb)
  }

  onStart = ({ canvas, canvasWidth, canvasHeight }) => {
    // const { scene, camera, renderer } = window.XR.Threejs.xrScene()
    const { scene, camera, renderer } = ThreejsPipeline.xrScene()
    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.renderer.context.getExtension('WEBGL_debug_renderer_info')
    window.XR.XrController.updateCameraProjectionMatrix({
      origin: this.camera.position,
      facing: this.camera.quaternion
    })
    document.querySelector('html').classList.add('launched')
    window.XRStarted = true
    if(this.experience && this.onStart){
      this.experience.onStart(this.camera.position, this.camera.quaternion)
    }
  }

  onUpdate = data => {
    this.camera.updateMatrixWorld()
    this.camera.matrixWorldInverse.getInverse(this.camera.matrixWorld)
    this.renderer.clear(true, true, true)
  }

  onFoundImageTarget = data => {
    const { name, position, rotation, scale, lighting } = data.detail
    if (this.experience && this.experience.onFoundImageTarget) {
      this.experience.onFoundImageTarget({ name, position, rotation, scale, lighting })
    }
  }

  onUpdatedImageTarget = data => {
    const { name, position, rotation, scale, lighting } = data.detail
    if (this.experience && this.experience.onUpdatedImageTarget) {
      this.experience.onUpdatedImageTarget({ name, position, rotation, scale, lighting })
    }
  }

  onLostImageTarget = data => {
    const { name, position, rotation, scale, lighting } = data.detail
    if (this.experience && this.experience.onLostImageTarget) {
      this.experience.onLostImageTarget({ name, position, rotation, scale, lighting })
    }
  }

  onRender = () => {
    if (this.experience) {
      this.experience.update()
    }
  }
}()